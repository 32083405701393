<template>
  <div class="innerPage" style="">
    <!-- <div class="trans1"> -->
    <div class="title flexCloumn" style="width: 100%;height: 50px; ">
      <div class="flexCenter" style="width: 100%;">{{ schoolname }}学生岗位实习离岗申请表</div>
    </div>
    <div class="form">
      <div class="table" style="height: 100%">
        <el-row class="flexStretch borderR">
          <el-col :span="2" class="cell cell50 flexCenter" style="">姓名</el-col>
          <el-col :span="6" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.stu_name }} </el-col>
          <el-col :span="2" class="cell cell50 flexCenter" style="">性别</el-col>
          <el-col :span="2" class="cell cell50 flexCenter" style=" ">{{ info.gender==1?'男':'女' }} </el-col>
          <el-col :span="2" class="cell cell50 flexCenter" style="">班级</el-col>
          <el-col :span="7" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.class_name }} </el-col>
          <el-col :span="4" class="cell cell50 flexCenter" style="">专业</el-col>
          <el-col :span="7" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.zy_name }} </el-col>
        </el-row>
        <el-row class="flexStretch borderR">
          <el-col :span="2" class="cell cell50 flexCenter" style="line-height:unset;">实习<br />单位</el-col>
          <el-col :span="10" class="cell cell50 flexCenter" style="line-height:unset;">{{
            info.remark.stu_enter_info.firm_name }} </el-col>
          <el-col :span="2" class="cell cell50 flexCenter" style="line-height:unset;">实习<br />岗位</el-col>
          <el-col :span="7" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.remark.stu_enter_info.job
            }} </el-col>
          <el-col :span="4" class="cell cell50 flexCenter" style="line-height:unset;">单位负责人<br />电话</el-col>
          <el-col :span="7" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.remark.mentor_tel }}
          </el-col>
        </el-row>
        <el-row class="flexStretch borderR">
          <el-col :span="2" class="cell cell50 flexCenter" style="line-height:unset;">上岗<br />日期</el-col>
          <el-col :span="10" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.leave_start }} </el-col>
          <el-col :span="2" class="cell cell50 flexCenter" style="line-height:unset;">离岗<br />日期</el-col>
          <el-col :span="7" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.leave_end }} </el-col>
          <el-col :span="4" class="cell cell50 flexCenter" style="line-height:unset;">家长电话</el-col>
          <el-col :span="7" class="cell cell50 flexCenter" style="line-height:unset;">{{ info.remark.contactTel }}
          </el-col>
        </el-row>
        <div style="width: 100%; height: calc(100% - 150px)" class="borderR borderB">
          <el-row class="" style="height: 25%">
            <el-col :span="2" class="cell flexCenter" style="width:44.69px;height: 100%; ">
              离岗<br />原因 </el-col>
            <el-col :span="22" class="flexCloumn" style="width:calc(100% - 44.69px);height: calc(100% - 0px)">
              <el-row class="flexStretch" style="width: 100%; height: calc(100% - 0px)">
                <el-col :span="24" class="cell"
                  style="font-size: unset; width: 100%; height: 100%; padding: 10px; ; overflow: hidden; box-sizing: border-box; position: relative; border-right: 0">
                  <div :id="'divCntnrA_' + idx" style="width: 100%; height: calc(100% - 60px); overflow: hidden">
                    <div :id="'resizeDivA_' + idx">
                      <!-- {{ info.leave_content }} -->
                      <!-- <div class="textIndent2 resizeDivA_div" v-if="info.contentArr && info.contentArr.length > 0"
                        v-for="(item, index) in info.contentArr"
                        style="width: 100%; word-break: break-all; white-space: pre-wrap">{{ item }}</div> -->
                      <div class="textIndent2 resizeDivA_div"
                        style="width: 100%; word-break: break-all; white-space: pre-wrap">{{ info.leave_content }}</div>
                    </div>
                  </div>
                  <div class="flexEnd"
                    style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 40px;">
                    <div class="flexCloumn" style="align-items: flex-end;">
                      <div class="flexStart" style="line-height: unset!important;">
                        <div>学生签字：</div>
                        <div style="width: 100px;"></div>
                      </div>
                      <div class="flexEnd" style="line-height: unset!important;">
                        &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="" style="height: 25%">
            <el-col :span="2" class="cell flexCenter" style="width:44.69px;height: 100%; "> 班主<br />任意<br />见</el-col>
            <el-col :span="22" class="flexCloumn" style="width:calc(100% - 44.69px);height: calc(100% - 0px)">
              <el-row class="flexStretch" style="width: 100%; height: calc(100% - 0px)">
                <el-col :span="24" class="cell"
                  style="font-size: unset; width: 100%; height: 100%; padding: 10px; ; overflow: hidden; box-sizing: border-box; position: relative; border-right: 0">
                  <div class="flexEnd"
                    style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 40px;">
                    <div class="flexCloumn" style="align-items: flex-end;">
                      <div class="flexStart" style="line-height: unset!important;">
                        <div>班主任签字：</div>
                        <div style="width: 100px;"></div>
                      </div>
                      <div class="flexEnd" style="line-height: unset!important;">
                        &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="" style="height: 25%">
            <el-col :span="2" class="cell flexCenter" style="width:44.69px;height: 100%; ">
              实习<br />单位<br />意见</el-col>
            <el-col :span="22" class="flexCloumn" style="width:calc(100% - 44.69px);height: calc(100% - 0px)">
              <el-row class="flexStretch" style="width: 100%; height: calc(100% - 0px)">
                <el-col :span="24" class="cell"
                  style="font-size: unset; width: 100%; height: 100%; padding: 10px; ; overflow: hidden; box-sizing: border-box; position: relative; border-right: 0">
                  <div class="flexEnd"
                    style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 40px;">
                    <div class="flexCloumn" style="align-items: flex-end;">
                      <div class="flexStart" style="line-height: unset!important;">
                        <div>负责人签字：</div>
                        <div style="width: 100px;"></div>
                      </div>
                      <div class="flexStart" style="line-height: unset!important;">
                        <div>单位盖章：</div>
                        <div style="width: 100px;"></div>
                      </div>
                      <div class="flexEnd" style="line-height: unset!important;">
                        &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="" style="height: 25%">
            <el-col :span="2" class="cell flexCenter" style="width:44.69px;height: 100%; "> 学校<br />意见</el-col>
            <el-col :span="22" class="flexCloumn" style="width:calc(100% - 44.69px);height: calc(100% - 0px)">
              <el-row class="flexStretch" style="width: 100%; height: calc(100% - 0px)">
                <el-col :span="24" class="cell"
                  style="font-size: unset; width: 100%; height: 100%; padding: 10px; ; overflow: hidden; box-sizing: border-box; position: relative; border-right: 0">
                  <div class="flexEnd"
                    style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 40px;">
                    <div class="flexCloumn" style="align-items: flex-end;">
                      <div class="flexStart" style="line-height: unset!important;">
                        <div>负责人签字：</div>
                        <div style="width: 100px;"></div>
                      </div>
                      <div class="flexStart" style="line-height: unset!important;">
                        <div>学校盖章：</div>
                        <div style="width: 100px;"></div>
                      </div>
                      <div class="flexEnd" style="line-height: unset!important;">
                        &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>

export default {
  name: 'roll',
  data() {
    return {
      dataover: false,
      userid: ''
    }
  },
  props: {
    info: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
    xunfangsp: {
      type: Number,
      default: 1
    },
    schoolname: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let _this = this
    if (_this.info.leave_content) {
      _this.$nextTick(() => {
        _this.resizeA()
        // _this.resizeB()
      })
    }
    if (_this.info.audit_remark) {
      _this.$nextTick(() => {
        // _this.resizeC()
      })
    }

  },
  methods: {
    resizeA1() {
      let id1 = 'divCntnrA'
      let id2 = 'resizeDivA'

      let divHeight = document.getElementById(id1).offsetHeight
      let textHeight = document.getElementById(id2).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 7) {
        document.getElementById(id2).style.fontSize = `${18 - i}px`
        textHeight = document.getElementById(id2).offsetHeight
        i++

      }
    },

   resizeA() {
      let id1 = `divCntnrA_${this.idx}`;
      let id2 = `resizeDivA_${this.idx}`;

      console.log(id1)
      let divHeight = document.getElementById(id1).offsetHeight;
      let textHeight = document.getElementById(id2).offsetHeight;
      let i = 1;
      console.log(124, "a", textHeight, divHeight, i);
      while (textHeight > divHeight && i < 7) {
        // 获取所有带有指定类名的元素
        var elements = document.getElementsByClassName("resizeDivA_div");
        // 遍历所有获取到的元素，并且设置fontSize
        for (var idx = 0; idx < elements.length; idx++) {
          // 检查元素是否是一个div，因为getElementsByClassName返回的是所有具有指定类名的元素
          if (elements[idx].tagName.toLowerCase() === "div") {
            elements[idx].style.fontSize = `${18 - i}px`; // 设置字体大小为16px
          }
        }
        // document.getElementById(id2).style.fontSize = `${18 - i}px`;
        textHeight = document.getElementById(id2).offsetHeight;
        i++;
      }
    },
    resizeA1() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB() {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      // console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    },

    resizeC() {
      let divHeight = document.getElementById(`divCntnrC_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivC_${this.idx}`).offsetHeight
      console.log('C', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivC_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivC_${this.idx}`).offsetHeight
        i++
      }
    },

  }
}
</script>

<style lang="less" type="text/less">
.stuRolls .ant-col {
  display: flex;
}
</style>
<style lang="less" type="text/less" scoped>
.innerPage {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px;
  overflow: hidden;
  font-size: 20px;
  box-sizing: border-box;

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .cell {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    word-break: break-all;

    min-height: 30px;
    font-size: 14px;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    height: 50px;
    font-size: 14px;
  }

  .title {
    font-size: 20px;
    line-height: unset;
    font-size: 16px;
  }

  .form {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0;
    border: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

  }
}

.trans1 {
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /*transform: rotate(-90deg);*/

}
</style>
